<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="red" text v-on="on">
        <v-icon left>mdi-plus</v-icon>
        Добавить станцию
      </v-btn>
    </template>
    <v-card class="px-2 pt-1 pb-3" outlined>
      <v-form ref="form" @submit.prevent="checkInput">
        <v-card-title class="text-h5 font-weight-bold">Добавление станции</v-card-title>
        <v-card-text class="mt-1">
          <span>Полное название станции</span>
          <v-text-field v-model="station.nameExpFullRu" :rules="rules" class="my-2" dense hide-details="auto" outlined />
          <span>Сокращенное название станции</span>
          <v-text-field v-model="station.nameExpShortRu" :rules="rules" class="my-2" dense hide-details="auto" outlined />
          <!-- <div class="mt-5">Пароль</div>
            <v-text-field v-model="user.password" type="password" :rules="rules" class="mt-2" dense hide-details="auto" outlined /> -->
          <span>Выберите дерекцию</span>

          <v-select class="my-2" v-model="station.directionToken" :items="getDirectionList()" dense hide-details="auto" outlined></v-select>
        </v-card-text>
        <v-card-actions class="mt-6">
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="red" text>Отмена</v-btn>
          <v-btn :loading="loading" type="submit" color="red" depressed>Добавить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <div class="notification">
      <Notification />
    </div>
  </v-dialog>
</template>
<script>
import Notification from '@/components/Notification';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      station: {
        nameExpFullRu: '',
        nameExpShortRu: '',
        directionToken: ''
      },
      rules: [(v) => !!v || 'Обязательное поле'],
      loading: false,
      dialog: false
    };
  },
  components: {
    Notification
  },
  computed: {
    directions() {
      if (!this.directionList) return [];
      return this.directionList;
    },

    ...mapGetters('station', ['directionList'])
  },
  methods: {
    updateStationList() {
      this.fetchStationsList();
    },
    getDirectionList() {
      let directionListForSelect = [];
      this.directions.forEach((direction) => directionListForSelect.push({ text: direction.nameRu, value: direction.token }));
      return directionListForSelect;
    },

    checkInput() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.submitData();
      }
    },

    submitData() {
      this.addStation(this.station)
        .then(() => {
          this.closeDialog();
          this.showNotification('Станция успешно добавлена', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.closeDialog();
          this.showNotification('Произошла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.updateStationList();
      this.station.nameExpFullRu = '';
      this.station.nameExpShortRu = '';
      this.station.directionToken = '';
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    ...mapActions('station', ['addStation']),
    ...mapActions('admin', ['fetchStationsList']),
    ...mapActions('notification', ['showSnack'])
  }
};
</script>
