<template>
  <div class="stations">
    <NavBar />
    <v-container fluid>
      <h1>Станции</h1>
      <v-row class="my-2">
        <v-col>
          <StationCreateDialog v-if="this.checkPermission([128, 64])" />
        </v-col>
      </v-row>
      <V-data-table :headers="header" :items="stations" disable-pagination hide-default-footer>
        <template v-slot:item.nameExpFullRu="{ item }">
          <tr>
            <td>
              <v-text-field v-model="item.nameExpFullRu" background-color="transparent" flat hide-details="auto" placeholder="Введите наименование" solo />
            </td>
          </tr>
        </template>
        <template v-slot:item.onlyCorpo="{ item }">
          <tr>
            <td>
              <v-select
                v-model="item.onlyCorpo"
                :items="isActivePropertyList()"
                background-color="transparent"
                flat
                hide-details="auto"
                placeholder=""
                solo
                color="#e21a1a"
              />
            </td>
          </tr>
        </template>
        <template v-if="this.checkPermission([128])" v-slot:item.disabledBySuperior="{ item }">
          <tr>
            <td>
              <v-select
                v-model="item.disabledBySuperior"
                :items="isActivePropertyList()"
                background-color="transparent"
                flat
                hide-details="auto"
                placeholder=""
                solo
                color="#e21a1a"
              />
            </td>
          </tr>
        </template>
        <template v-slot:item.direction="{ item }">
          <tr>
            <td>
              <span>{{ item.direction.nameRu }}</span>
            </td>
          </tr>
        </template>
        <template v-slot:item.actions="{ item }" v-if="this.checkPermission([128, 64, 32])">
          <v-btn :disabled="itemStatus(item)" @click="editStationClick(item)" color="#e21a1a" text>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
      </V-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NavBar from '@/components/NavBar';
import StationCreateDialog from '@/components/StationCreateDialog';
import Helper from '@/helpers/reference/reference.helper';

export default {
  data() {
    return {
      newStation: {
        name: '',
        city: '',
        companyId: null,
        disabled: false
      },
      headers: [
        {
          text: 'Вокзал',
          value: 'nameExpFullRu'
        },
        {
          text: 'Корпоративный',
          value: 'onlyCorpo'
        },
        {
          text: 'Забанен',
          value: 'disabledBySuperior'
        },
        {
          text: 'Дирекция',
          value: 'direction'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      addStationDialog: false,
      notEmpty: [(v) => !!v || 0 || 'Обязательное поле']
    };
  },
  components: {
    NavBar,
    StationCreateDialog
  },
  mounted() {
    this.updateStationList();
    if (this.checkPermission([128, 64])) {
      this.updateDirectionList();
    }
  },
  computed: {
    header() {
      if (this.checkPermission([64]))
        return [
          {
            text: 'Вокзал',
            value: 'nameExpFullRu'
          },
          {
            text: 'Корпоративный',
            value: 'onlyCorpo'
          },
          {
            text: 'Дирекция',
            value: 'direction'
          },
          {
            text: '',
            value: 'actions'
          }
        ];
      return [
        {
          text: 'Вокзал',
          value: 'nameExpFullRu'
        },
        {
          text: 'Корпоративный',
          value: 'onlyCorpo'
        },
        {
          text: 'Забанен',
          value: 'disabledBySuperior'
        },
        {
          text: 'Дирекция',
          value: 'direction'
        },
        {
          text: '',
          value: 'actions'
        }
      ];
    },
    stations() {
      if (!this.stationsList) return [];
      return JSON.parse(JSON.stringify(this.stationsList));
    },
    directions() {
      if (!this.directionList) return [];
      return this.directionList;
    },
    loadingStationsProgress() {
      if (this.isStationsLoading) {
        return 'Список станции загружается';
      } else if (this.isStationsError) {
        return `Возникла ошибка ${this.isStationErrorMessage}`;
      } else if (!this.stationsList.stations || this.stationsList.stations.length <= 0) {
        return 'Список станций пуст';
      } else return `Всего станций: ${this.stations.length}`;
    },
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('admin', ['stationsList']),
    ...mapGetters('admin', ['isStationsLoading']),
    ...mapGetters('admin', ['isStationsError']),
    ...mapGetters('admin', ['stationsErrorMessage']),
    ...mapGetters('station', ['directionList'])
  },
  methods: {
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    updateStationList() {
      this.fetchStationsList();
    },
    updateDirectionList() {
      this.fetchDirectionList();
    },
    updateCompanyList() {
      this.fetchCompanyList();
    },
    closeStationDialog() {
      this.addStationDialog = false;
    },
    isActivePropertyList() {
      return Helper.getActiveProperty();
    },
    itemStatus(item) {
      let defaultTag = this.stationsList.find((station) => station.token === item.token);
      let status = true;
      for (let prop in item) {
        if (typeof item[prop] !== 'object' && item[prop] !== defaultTag[prop]) {
          status = false;
        }
      }
      return status;
    },
    getBooleanValue(value) {
      return Helper.getActiveName(value);
    },
    getCompanyName(companyId) {
      const currentCompany = this.companies.find((company) => company.id === companyId);
      return currentCompany ? currentCompany.name : 'Не найдено';
    },

    editStationClick(item) {
      item.stationToken = item.token;
      this.editStation(item)
        .then(() => {
          this.updateStationList();
          this.showNotification('Cтанция отредактирована', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.updateStationList();
          this.showNotification('Возникла ошибка ', 'error', '2000', 'mdi-check');
        });
    },
    ...mapActions('station', ['fetchDirectionList']),
    ...mapActions('station', ['editStation']),
    ...mapActions('admin', ['fetchStationsList'])
  }
};
</script>

<style scoped>
.ClientMain {
  /* background-color: rgba(57, 74, 88, 0.2); */
  font-family: RussianRail;
}

.Reference {
  border-radius: 10px;
  margin: 10%;
  background-color: white;
  padding-bottom: 30px;
}

.NameOfService {
  padding-top: 30px;
  text-align: center;
}

.text-black input {
  color: black !important;
}
</style>
